import React from "react";
import './Home.css';
import Login from "./Login.js"
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import Register from "./Register";



function Navbar() {
    const navigate = useNavigate();
    const [isShowLogin, setIsShowLogin] = useState(true);
    const [isShowRegister, setIsShowRegister] = useState(true);

    const handleLoginClick = () => {
        setIsShowLogin((isShowLogin) => !isShowLogin);
    }

    const handleRegisterClick = () => {
        setIsShowRegister((isShowRegister) => !isShowRegister);
    }

    console.log("local storage: " + localStorage.getItem("token"));

    // if (!localStorage.getItem("token")) {
    //     isShowLogin = false;
    //     // return <Navigate to="/login" />
    // } 
    // ^Not working good, better avoid!

    return (
            <nav>
                <div className="nav-align">
                    <div className="logo" onClick={() => navigate("/")}>
                        <div className="bulgarian"><strong>BULGARIAN MADE</strong></div>
                        <div>opportunities for growth</div>
                    </div>
                    <div className="navigation">
                        <ul>
                            <li><Link to="/Ideas">Invest</Link></li>
                            <li><Link to="/form">Grow</Link></li>
                            <li>
                                {(
                                    () =>
                                    {
                                        if (!localStorage.getItem("username")) {
                                            return <button className="logoff" onClick={() => {
                                                handleLoginClick();
                                                console.log(isShowLogin);
                                                // navigate("/login");
                                                }}>Log in
                                            </button>
                                        } else {
                                            return <button className="logoff" onClick={() => {
                                                localStorage.removeItem("token");
                                                localStorage.removeItem("username");
                                                localStorage.removeItem("user_id");
                                                navigate("#");
                                                }}>Log out
                                            </button>
                                        }
                                    }
                                 )()
                                }
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="login-container"><Login isShowLogin={isShowLogin} handleLoginClick={handleLoginClick} handleRegisterClick={handleRegisterClick}/></div>
                <div className="login-container"><Register isShowRegister={isShowRegister} handleRegisterClick={handleRegisterClick}/></div>
            </nav>
            )
}

export default Navbar;