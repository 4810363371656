import React, { useState } from 'react';
import { useSearchParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import {postRequest} from "../strapi.js";
import './Login.css'

export default function ResetPassword() {
    const navigate = useNavigate();
    const [password, setPassword] = useState();
    const [retypePassword, setRetypePassword] = useState();
    const [errorMsg, setErrorMsg] = useState("");

    const [searchParams, setSearchParams] = useSearchParams();
    const code = searchParams.get("code");

    const handleSubmit = async e => {
        e.preventDefault();
        
        postRequest("/api/auth/reset-password", {
            code: code,
            password: password,
            passwordConfirmation: retypePassword
        }).then(result => {
            if (result["error"]) {
                setErrorMsg("Log in failed")
                console.log(JSON.stringify(result["error"]));
                return;
            }
                setErrorMsg('');
                navigate('/');
            });
    };
    
    return (
        <div  className="login-wrapper">
            <div className="login-window">
                <h2>Please enter new password</h2>
                <form onSubmit={handleSubmit}>
                    <label htmlFor="Password">Password</label>
                    <br /><br />
                        <input type="password" onChange={e => setPassword(e.target.value)} name="Password" placeholder="Password" autoComplete="current-password" />
                    <br />
                    <label htmlFor="RetypePassword">Retype Password</label>
                    <br /><br />
                        <input type="password" onChange={e => setRetypePassword(e.target.value)} name="RetypePassword" placeholder="RetypePassword"/>
                    <br />
                    <br />
                    <br />
                    <div className='submit'>
                        <button type="submit">Submit</button>
                    </div>
                </form> 
                <br />
            </div>
            <br />
        </div>
    )
}
