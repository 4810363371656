import React from "react";
import { useState, useRef, useEffect } from "react";
import KaTable from "./KaTable.js";

let loadideas = true;
let b = "a";

export default function Ideas() {
    const [a, setA] = useState(b);
  
    
  if (loadideas) {
    b = "b";
    setA(b);
    loadideas = false;
    console.log("load values finished");
  }

  console.log(a);
  console.log("loading page finished");
  return <div className="mid-container"></div>;
}
