import React, { useState } from 'react';
import { Link } from "react-router-dom";
// import { postRequest, forgottenPassword } from '../restClient';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {postRequest} from "../strapi.js";
import './Login.css'

export default function Login({isShowLogin, handleLoginClick, handleRegisterClick}) {
    const navigate = useNavigate();
    const [username, setUserName] = useState();
    const [password, setPassword] = useState();
    const [errorMsg, setErrorMsg] = useState("");

    const handleSubmit = async e => {
        e.preventDefault();
        
        postRequest("/api/auth/local", {
            identifier: username,
            password: password
        }).then(result => {
            if (result["error"]) {
                setErrorMsg("Log in failed")
                console.log(JSON.stringify(result["error"]));
                return;
            }
                let userObj = result["user"];
                localStorage.setItem("token", result["jwt"]);
                localStorage.setItem("username", userObj["username"]);
                localStorage.setItem("user_id", userObj["id"]);
                handleLoginClick();
                setErrorMsg('');
                navigate('/');
            });
    };
    
    function resetPassword() {
        postRequest("api/auth/forgot-password", {
            email: username
        }).then(result => {
            if (result["error"]) {
                setErrorMsg(JSON.stringify(result["error"]));
                return;
            }
    
            setErrorMsg("PasswordResetEmailSent.");
        });

    };

    return (
        <div className={`login-wrapper ${isShowLogin ? "show" : "hide"}`}>
            <div className="login-window">
                <h2>Please Log In</h2>
                <form onSubmit={handleSubmit}>
                    <label htmlFor="Username">Username or e-mail</label>
                    <br /><br />
                        <input type="text" onChange={e => setUserName(e.target.value)} name="Username" placeholder="Username or e-mail" autoComplete="username" />
                    <br /><br />
                    <label htmlFor="Password">Password</label>
                    <br /><br />
                        <input type="password" onChange={e => setPassword(e.target.value)} name="Password" placeholder="Password" autoComplete="current-password" />
                    <br />
                    <br />
                    <br />
                    <div className='submit'>
                        <button type="submit" /*onClick={handleLoginClick}*/>Log in</button>
                    </div>
                </form> 
                <br />

                <div className='register'>
                    <button onClick={() => {handleRegisterClick(); handleLoginClick()}}>Register</button>
                </div>

                <br /><br />
                <div>
                    <button onClick={() => resetPassword()}>Reset Forgotten Password</button>
                </div>
                <br /><br /><br />
                <span className="close-link" onClick={() => handleLoginClick()}>Close</span>
            </div>
            <br />

            <div className={`login-error ${errorMsg ? "hide" : "show"}`}>
                <p>{errorMsg}</p>
            </div>
        </div>
    )
}
