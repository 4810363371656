import React from "react";
import { useRef } from "react";
import { Pagination, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import {SERVER_ADDRESS, putRequest, useFetch} from "../strapi.js"


import { useParams } from "react-router-dom";
import NoPage from "./NoPage";


export default function Idea() {
    const rangeValueRef = useRef(null);
    let { id } = useParams();
    // const [errorMsg, setErrorMsg] = useState("");

    const {loading, error, data} = useFetch("/api/projects/" + id + "?populate=*", localStorage.getItem("token"))
    console.log(data)

    
    if(loading){
      return (<div className="mid-container nav-align">
      <p>loading...</p>
      </div>)
    }
    if(error){
      return (<div className="mid-container nav-align">
      <p>{JSON.stringify(error)}</p>
      </div>)
    }

function createSlides(ideaX) {

  if(ideaX){

    if (ideaX.attributes.images.data) {
      return ideaX.attributes.images.data.map(imgObj => {
        console.log("imgobj:")
        console.log(imgObj);
        return <SwiperSlide key={imgObj.id}>
          <img alt="Project pic" src={SERVER_ADDRESS + imgObj.attributes.url} />
          </SwiperSlide>
      })
    } else {
       return <SwiperSlide> 
      <img alt="Project pic" src={SERVER_ADDRESS + "/uploads/No_Project_Img_cc993a93a7.jpg?updated_at=2023-02-20T17:58:16.958Z"} />
      </SwiperSlide>
    }
  }

  return<></>;
}


    if (isNaN(id)){ 
        return (<NoPage />);
    }

    if (data) {
      const handleSubmit = async e => {
        e.preventDefault();
        let objData = {
          businessValue: rangeValueRef.current.value
        }
        console.log(objData);
        let form = new FormData();
        form.append("data", JSON.stringify(objData))
        
        putRequest("/api/projects/" + data["id"], form, localStorage.getItem("token")).then(result => {
          if (result["error"]) {
            //setErrorMsg(JSON.stringify(result["error"]));
            return;
          }})
      
      }
      console.log(data.attributes.info)
      return (
          <div className="nav-align">
            <h1>{data.attributes.Title}</h1>
            <br />
            <h2>Type: {data.attributes.BusinessType}</h2>
                
            <div className="mid-container-idea">
            
              <div className="swiperIdea">
              <br />
            
                <div id="swippper">
                  <Swiper
                    pagination={{
                      type: "progressbar",
                    }}
                    navigation={true}
                    modules={[Pagination, Navigation]}
                    className="mySwiper"
                  >
                    {createSlides(data)};
                  </Swiper>
                </div>
              </div>
              
              <div className="ideaInfo">
                <div className="display-linebreak">{data.attributes.info.replace(/[\u2028]/g, " ")}</div>
                <br/><br/>
                      <form id="investForm" onSubmit={handleSubmit}>
                        <output htmlFor="range">Set buy price: {} </output>
                        <br />
                        <input ref={rangeValueRef} type="range" id="range" name="range" min={0} max={10000000} defaultValue={data.attributes.businessValue} />
                        <br />
                        <input type="submit" value={"Submit"} />
                      </form>

                    {error ? <p>error: {error}</p> : <></> }
              </div>
              
            </div>
          </div>
        );
      }
}

