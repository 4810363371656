import React from "react";
import { useState, useRef} from "react";
import ImageUploading from "react-images-uploading";
import { Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import {postRequest} from "../strapi.js"
import { useForm } from "react-hook-form";


function Form() {

  const [id] = useState(0);
  const [images, setImages] = useState([]);

  
  const { register, handleSubmit, getValues } = useForm();
  const emailRef = useRef(null);
  
  
  const [username] = useState(localStorage.getItem("username"));
  const [errorMsg, setErrorMsg] = useState("");

  const handleSubmitPost = async () => {
    let objData = {
    username: username,
    Title: getValues('title'),
    email: getValues('email'),
    info: getValues('info'),
    BusinessType: getValues('businessType'),
    submitted: Date.now(),
    user: [localStorage.getItem("user_id")]
    }
    let form = new FormData();
    form.append("data", JSON.stringify(objData))
    images.forEach(image => {
       form.append("files.images", image["file"])  
    });
    console.log(form);
    
    postRequest("/api/projects", form, localStorage.getItem("token")).then(result => {
      if (result["error"]) {
        setErrorMsg(JSON.stringify(result["error"]));
        return;
      }})
  }

  const maxNumber = 5;

  console.log(images.length);

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    console.log(imageList, addUpdateIndex);
    setImages(imageList);
  };

// INVOKED IN TEST BUTTON FOR REF
  function testRef(reff) {
    console.log(reff);
    console.log(getValues())
  }

  const { ref, ...rest } = register('email')
  
  // const EmailInput = React.forwardRef(({name}, ref) => (
  //   <input {...rest} name={'email'} ref={(e) => {
  //     ref(e)
  //     emailRef.current = e;
  //   }} />
  // ))
 
  return (
    <div className="nav-align">
      <div className="fieldset">
        <div className="formLeft">
        <form id="projectForm" onSubmit={handleSubmit(handleSubmitPost)}>
            <div>
              <label htmlFor="title">Title</label>
              <input type="text" id="title" name="title" placeholder="First title" defaultValue={username} {...register("title")} />
              <br /><br />
              <label htmlFor="firstName">First Name</label>
              <br /><br />
              <input type="text" id="firstName" name="firstName" placeholder="First Name" defaultValue={username} {...register("firstName")} />
            </div>
            <br />
            <div>
              <label htmlFor="email">E-Mail</label>
              <br /><br />
              {/* <EmailInput name={"email"} ref={emailRef}/> */}
              {/* <input type="text" id="email" name="email" placeholder="E-Mail" ref={emailRef} {...register("email")} /> */}
              <input {...rest} name={'email'} ref={(e) => {ref(e); emailRef.current = e}} placeholder="E-Mail" />

            </div>
            <br />
            <div>
              <label htmlFor="businessType">Business type</label>
              <br /><br />
              <select id="businessType" className="inputData" name="businessType" {...register("businessType")}>
                <option value="Startup">Startup</option>
                <option value="Small Business">Small Business</option>
              </select>
            </div>
            <br /><br />
            <label htmlFor="info">Info</label>
            <br /><br />
              <textarea type="textarea" id="info" name="info" placeholder="Please write more about your project..." {...register("info")} />
              <br />
          </form>
        </div>

        <div className="formRight">
      <ImageUploading
        multiple
        value={images}
        onChange={onChange}
        maxNumber={maxNumber}
        dataURLKey="data_url"
      >
        {({
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
        }) => (
          // write your building UI
          // <div className="upload__image-wrapper">
          <>
            <div>
              <p>Images</p>
              <button
                style={isDragging ? { color: "red" } : undefined}
                onClick={() => onImageUpload()}
                {...dragProps}
              >
                Click or Drop here
              </button>
              <button type="submit" form="projectForm" value="Submit">Submit</button>
              {images.length >= 1 ? <button onClick={() => onImageRemoveAll()}>Remove all images</button> : <></>}
              {/* <button onClick={() => testRef(emailRef)}>Test</button> */}
            </div>

            <div id="swippper">
              <Swiper
                pagination={{
                  type: "progressbar",
                }}
                navigation={true}
                modules={[Pagination, Navigation]}
                className="mySwiper"
              >
                {createLocalSlides(createImageURLs(images, localStorage.getItem("user_id"), id), onImageRemove)};
              </Swiper>
            </div>
          {/* </div> image wrapper end*/}
            </>
        )}
      </ImageUploading>

      </div>  
    </div>


      <div>
        <p>Graceful Error: {errorMsg}</p>
      </div>
    </div>
  );
}


// function createSlides(urls) {
//   return urls.map(url => {
//     return <SwiperSlide>
//       <img src={url} />
//     </SwiperSlide>
//   });
// }

function createLocalSlides(urls, onImageRemove) {
  return urls.map((url, index) => {
    return (
      <SwiperSlide> 
        <div key={url} className="image-item">
          <img src={url} alt={`img: ${index}`} className="image" />
          <div className="image-item__btn-wrapper">
            <div className="text">
              <button onClick={() => {onImageRemove(index); console.log("Image for removal index: " + index)}}>Remove</button>
            </div>
          </div>
        </div>
      </SwiperSlide>
    );
  });
}


function getImageId(ideaId, imageName, index) {
  return localStorage.getItem("user_id") + "_" + ideaId + "_" + imageName + "_" + index;
}

function getImageURL(image){
  return image.data_url;
}

function createImageURLs(images, user_id, ideaId) {
  console.log(images);
  return images.map((image, index) => {
    let imageId = getImageId(ideaId, image["file"].name, index);
    let imageURL = getImageURL(image);
    console.log(imageURL)
    return imageURL;
  })
};



export default Form;
