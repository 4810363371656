import React from "react";
import './Home.css';
import Login from "./Login.js"
import Articles from "./Articles";
import { Link } from "react-router-dom";
import main_banner from "./../imgs/main_banner.jpg";
import useFetch from "../strapi.js";


function Home() {
    function scrollTop() {
        document.body.scrollTop = 0; // Safari
        document.documentElement.scrollTop = 0; // Chrome, Firefox, IE, Opera
    }

    // //fetching:
    // const {loading, error, data} = useFetch("/api/projects?populate=*", localStorage.getItem("token"));

    // function articles() {
    //         if (loading) {
    //           return (
    //             <div className="articles">
    //               <p>loading...</p>
    //             </div>
    //           );
    //         }
    //         if (error) {
    //           return (
    //             <div className="articles">
    //               <p>{JSON.stringify(error)}</p>
    //             </div>
    //           );
    //         }
    //         if (data) {
    //           return (<div className="articles">
    //             {data.slice(0, 3).map((a) => {
    //               console.log(a);
    //               return (
    //                 <article
    //                   key={a["id"].toString()}
    //                   style={{
    //                     backgroundImage: `url(${SERVER_ADDRESS + a.attributes.images.data[0].attributes.url})`,
    //                   }}
    //                 >
    //                   <div className="hero-text">
    //                     <h3>{a["id"]}</h3>
    //                     <Link to={"/ideas/" + a["id"]}>
    //                       <button>SEE PROJECT</button>
    //                     </Link>
    //                   </div>
    //                 </article>
    //               );
    //             })}
    //           </div>)
    //         }
    //       }
    
    
    // const navigate = useNavigate();
    // const [isShowLogin, setIsShowLogin] = useState(true);

    // const handleLoginClick = () => {
    //     setIsShowLogin((isShowLogin) => !isShowLogin);
    //     console.log('yeehaa', isShowLogin);
    // }

    console.log("local storage: " + localStorage.getItem("token"));

    // if (!localStorage.getItem("token")) {
    //     isShowLogin = false;
    //     // return <Navigate to="/login" />
    // } 
    // ^Not working good, better avoid!

    return (
      <div className="Home">
        {/* <nav>
                <div className="nav-align">
                    <div className="logo">
                        <div><strong>BULGARIAN MADE</strong></div>
                        <div>opportunities for growth</div>
                    </div>
                    <div className="navigation">
                        <ul>
                            <li><Link to="/Ideas">Invest</Link></li>
                            <li><a href="#">Grow</a></li>
                            <li>
                                
                                {(
                                    () =>
                                    {
                                        if (!localStorage.getItem("username")) {
                                            return <button className="logoff" onClick={() => {
                                                handleLoginClick();
                                                console.log(isShowLogin);
                                                // navigate("/login");
                                                }}>Log in
                                            </button>
                                        } else {
                                            return <button className="logoff" onClick={() => {
                                                localStorage.removeItem("token");
                                                localStorage.removeItem("username");
                                                localStorage.removeItem("user_id");
                                                navigate("#");
                                                }}>Log out
                                            </button>
                                        }
                                    }
                                 )()
                                }

                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <div className="login-container"><Login isShowLogin={isShowLogin} handleLoginClick={handleLoginClick}/></div> */}
        <main>
          <div>
            <div className="main-1">
              <div className="main-1-left">
                <h1>WE HELP BULGARIAN BUSINESS PROSPER</h1>
                <p>
                  <i>
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                    Cupiditate, modi soluta! Veritatis animi soluta, ea expedita
                    rerum vero sapiente ducimus. Quas, corporis dolorum placeat
                    consequatur tempore molestiae quo ducimus labore
                    voluptatibus
                  </i>
                </p>
                <Link to="/ideas" onClick={() => scrollTop()}>
                  <button>Investment opportunities</button>
                </Link>
                <Link to="/form" onClick={() => scrollTop()}>
                  <button>Grow your idea</button>
                </Link>
              </div>
              <div className="main-1-right">
                <img src={main_banner} alt={"banner img"} />
              </div>
              {/* <div><img src={require('./../imgs/kunkka.jpg')} /></div> */}
            </div>
          </div>
          <div className="main-2">
            <div>
              <h1>INVESTMENT OPPORTUNITIES</h1>
              <p>
                <i>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Non,
                  consequuntur magni alias temporibus doloribus, nesciunt
                  dolorum fugit, incidunt hic eveniet saepe eos illo sunt
                  corrupti.
                </i>
              </p>
            </div>
            <div>
            <Articles sliceIndex={0} />
            </div>
              {/* <div className="articles">
                            <article>
                                <div className="hero-text">
                                    <h3>HEALTHMAKERS</h3>
                                    <button>SEE PROJECT</button>
                                </div>
                            </article>
                            <article>
                                <div className="hero-text">
                                    <h3>За Младите</h3>
                                    <button>SEE PROJECT</button>
                                </div>
                            </article>
                            <article>
                                <div className="hero-text">
                                    <h3>OnRock</h3>
                                    <button>SEE PROJECT</button>
                                </div>
                            </article>
                        </div> */}
            <div className="enroll-articles">
              <article>
                <h2>GROW YOUR IDEA OR BUSINESS</h2>
                <p>
                  <i>
                    Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                    Repellendus possimus, odio quas, cupiditate molestias
                  </i>
                </p>
                <Link to="/form" onClick={() => scrollTop()}>
                  <button>Apply now</button>
                </Link>
              </article>
              <article>
                <h2>GROW YOUR IDEA OR BUSINESS</h2>
                <p>
                  <i>
                    Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                    Repellendus possimus, odio quas, cupiditate molestias
                  </i>
                </p>
                <Link to="/ideas" onClick={() => scrollTop()}>
                  <button>Sign up</button>
                </Link>
              </article>
            </div>
          </div>
        </main>

        {/* <footer>
                <div className="footerdiv">

                    <div>
                        <h4>Growth opportunities</h4>
                        <ul>
                            <li>seed funding</li>
                            <li>growth funding</li>
                            <li>partnership opportunities</li>
                        </ul>
                        <br /><br /><br />
                        <h4>Investment opportunities</h4>
                        <ul>
                            <li>startups</li>
                            <li>small business</li>
                        </ul>
                    </div>
                    <div>
                        <h4>Careers</h4>
                        <ul>
                            <li>find motivated and qualified personnel</li>
                            <li>search for a job in exchange for equity</li>
                        </ul>
                        <br /><br /><br />
                        <h4>Partnership network</h4>
                        <ul>
                            <li>premium investment opportunities</li>
                            <li>discount for services and products</li>
                        </ul>
                    </div>
                    <div>
                        <h4>About</h4>
                        <ul>
                            <li>Global benefits</li>
                            <li>Investor benefits</li>
                            <li>Funding benefits</li>
                            <li>Partnership benefits</li>
                        </ul>
                    </div>

                </div>
            </footer> */}
      </div>
    );
}

export default Home;
