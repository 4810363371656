import React from "react";

import { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import getIdeasCount from "./getIdeasCount.js";
import KaTable from "./KaTable.js";
import { useFetch, SERVER_ADDRESS } from "../strapi.js";
import { type } from "@testing-library/user-event/dist/type/index.js";


export default function Ideas() {
  const [ideas, setIdeas] = useState(null);
  const [errorMsg, setErrorMsg] = useState("");
  let loadideas = true;
  
//fetching:
const {loading, error, data} = useFetch("/api/projects?populate=*", localStorage.getItem("token"))

//Render logic:
if(loading){
  return (<div className="mid-container nav-align">
  <p>loading...</p>
  </div>)
}
if(error){
  return (<div className="mid-container nav-align">
  <p>{JSON.stringify(error)}</p>
  </div>)
}


// splitting ideas into [(3)] arrays;
  let ideasUpdateForArticleRender = [];
  if (data !== null) {
      for (let i = 0; i < data.length; i += 3) {
      ideasUpdateForArticleRender.push(data.slice(i, i + 3));
    }}
  console.log(ideasUpdateForArticleRender);

  // function getPending(showPending) {
  //   if (showPending) {
  //     return (
  //       <Link to={"/pending"}><button>SHOW PENDING</button></Link>
  //     );
  //   }
  //   return <></>;
  // }

  return (
    <div className="nav-align">
      <div className="mid-container">
        {/* {getPending(showPending)} */}
        {ideasUpdateForArticleRender.map((x,i) => { return (
          <div className="articles" key={i.toString()}>
            {x.map((a) => { console.log(a); 
            return (
              <article key={a.attributes.Title.toString()} style={{ backgroundImage: `url(${SERVER_ADDRESS + (a.attributes.images.data ? a.attributes.images.data[0].attributes.url : '/uploads/No_Project_Img_cc993a93a7.jpg?updated_at=2023-02-20T17:58:16.958Z')})` }}>
                <div className="hero-text">
                  <h3>{a.attributes.Title.toString()}</h3>
                  <Link to={"/ideas/" + a["id"]}> <button >SEE PROJECT</button></Link>  
                </div>
              </article>
            )})}
          </div>
        )})}
      </div>

      <p>{errorMsg}</p>
    </div>
  );

// return  (<div className="mid-container nav-align">
//   <p> {JSON.stringify(data)} </p>
//   </div>)

  // console.log("rendering..");
  // useEffect(() => {
  //   if (ideas == null) {
  //     console.log("ideas requested");
  //     getIdeasCount().then((x) => {setIdeas(x)
  //       console.log("ideas set")
  //       console.log(x.ideas.length);});
  //   }
  // }, []);

  // console.log('opalq')
  // console.log("ids:" + ideas)
  // console.log(getIdeasCount())
  
  //  if (ideas.length == 0) {
  //    getIdeas().then(result => {
  //      console.log(result);
  //      if (result.hasOwnProperty("error")) {
  //        setErrorMsg(JSON.stringify(result["error"]));
  //        return;
  //      }
  //      result.text().then(t => {
  //        setIdeas(JSON.parse(t))
  //       console.log("result done")});
  //    });
  //  }
  

  // console.log("ideasFor " + ideasUpdateForArticleRender)
  
  // let showPending = false;
  // if(localStorage.getItem("username") === "Tinkuuu" || localStorage.getItem("username") === "anton"|| localStorage.getItem("username") === "valentin"){
  //   showPending=true;
  // }


}
