// import React, { useRef, useState } from "react";

import { Pagination, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
// import 'swiper/modules/pagination/pagination.min.css';
// import 'swiper/modules/navigation/navigation.min.css';

import 'swiper/css/scrollbar';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './swippper.css'
// import 'swiper/swiper.min.css'


export default function Swippper() {
  return (
    <div id="swippper">
      <Swiper
        pagination={{
          type: "progressbar",
        }}
        navigation={true}
        modules={[Pagination, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide><img src={require('./../imgs/bulgaria.png')}/></SwiperSlide>
        <SwiperSlide><img src={require('./../imgs/kunkka.jpg')}/></SwiperSlide>
        <SwiperSlide><img src={require('./../imgs/visage.jpg')}/></SwiperSlide>
        <SwiperSlide><img src={require('./../imgs/kunkka.jpg')}/></SwiperSlide>
        <SwiperSlide><img src={require('./../imgs/doom.jpg')}/></SwiperSlide>
        <SwiperSlide><img src={require('./../imgs/kunkka.jpg')}/></SwiperSlide>
      </Swiper>
    </div>
  );
}