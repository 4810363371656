import React from "react";
import Home from "./Home.js";
import Form from "./Form";
import Ideas from "./Ideas";
import Idea from "./Idea";
import NoPage from "./NoPage";
import Testing from "./Testing";
import Layout from "./Layout";
import Swippper from "./Swippper"
import ResetPassword from "./ResetPassword"

import { BrowserRouter, Routes, Route } from "react-router-dom";
export default function App() {
    return (
        <React.StrictMode>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Layout />}>
                        <Route index element={<Home />} />
                        <Route path="form" element={<Form />} />
                        <Route path="testing" element={<Testing />} />
                        <Route path="ideas" element={<Ideas />} />
                        <Route path="ideas/:id" element={<Idea />} />
                        <Route path="swiper" element={<Swippper />} />
                    </Route>
                    <Route path="/reset-password" element={<ResetPassword />}/>
                    <Route path="*" element={<NoPage />} />
                </Routes>
            </BrowserRouter>
        </React.StrictMode>);
}
