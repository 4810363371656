import React from "react"

function Footer() {
    return (
    <footer>
        <div className="footerdiv">

            <div>
                <h4>Growth opportunities</h4>
                <ul>
                    <li>seed funding</li>
                    <li>growth funding</li>
                    <li>partnership opportunities</li>
                </ul>
                <br /><br /><br />
                <h4>Investment opportunities</h4>
                <ul>
                    <li>startups</li>
                    <li>small business</li>
                </ul>
            </div>
            <div>
                <h4>Careers</h4>
                <ul>
                    <li>find motivated and qualified personnel</li>
                    <li>search for a job in exchange for equity</li>
                </ul>
                <br /><br /><br />
                <h4>Partnership network</h4>
                <ul>
                    <li>premium investment opportunities</li>
                    <li>discount for services and products</li>
                </ul>
            </div>
            <div>
                <h4>About</h4>
                <ul>
                    <li>Global benefits</li>
                    <li>Investor benefits</li>
                    <li>Funding benefits</li>
                    <li>Partnership benefits</li>
                </ul>
            </div>

        </div>
    </footer>
    )
}

export default Footer