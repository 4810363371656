import React, { useState } from 'react';
import { Link } from "react-router-dom";
// import { userRegister, userResendMail, postRequest } from '../restClient';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {postRequest} from "../strapi.js";
import './Login.css'

export default function Register({isShowRegister, handleRegisterClick}) {
    const navigate = useNavigate();
    const [username, setUserName] = useState();
    const [password, setPassword] = useState();
    const [confirmPassword, setConfirmPassword] = useState();
    const [email, setEmail] = useState();
    const [errorMsg, setErrorMsg] = useState("");

    
    const handleSubmit = async e => {
        e.preventDefault();

        console.log("starting...")
        if (confirmPassword != password) {
            setErrorMsg("passwords didnt match");
            return;
        }
        console.log("more...")
        
   
        postRequest("/api/auth/local/register", {
            username: username,
            password: password,
            email: email
        }).then(result => {
            if (result["error"]) {
                setErrorMsg(JSON.stringify(result["error"]));
                console.log("error msg: " + errorMsg);
                return;
            }


            console.log("registered successfully");
            handleRegisterClick();
        });
    }

    const handleResendClick = async e => {
   
        postRequest("/api/auth/send-email-confirmation", {
            email: email
        }).then(result => {
            if (result["error"]) {
                setErrorMsg(JSON.stringify(result["error"]));
                console.log("error msg: " + errorMsg);
                return;
            }


            console.log("confirmation email resend");
        });
    }

    // function resendEmail() {
    //     userResendMail({
    //         username: username,
    //         password: password,
    //         email: username

    //     }).then(result => {
    //         if (result["error"]) {
    //             setErrorMsg(JSON.stringify(result["error"]));
    //             return;
    //         }

    //         setErrorMsg("Confirmation email resend.");
    //     });
    // }

    return (
        <div className={`register-wrapper ${isShowRegister ? "show" : "hide"}`}>
            <div className='register-window'>
                <h1>Please fill in</h1>
                <form onSubmit={handleSubmit}>
                    <label htmlFor="Username">Username</label>
                    <br /><br />
                        <input type="text" name="Username" placeholder="Username" onChange={e => setUserName(e.target.value)} />
                    <br /><br />
                    <label htmlFor="Email">E-mail</label>
                    <br /><br />
                        <input type="text" name="Email" placeholder="Email" autoComplete="email" onChange={e => setEmail(e.target.value)} />
                    <br /><br />
                    <label htmlFor="Password">Password</label>
                    <br /><br />
                        <input type="password" name="Password" placeholder="Password" autoComplete="new-password" onChange={e => setPassword(e.target.value)} />
                    <br /><br />
                    <label htmlFor="retypePassword">Retype Password</label>
                    <br /><br />
                        <input type="password" name="retypePassword" placeholder="Password" autoComplete="new-password" onChange={e => setConfirmPassword(e.target.value)} />
                    <br /><br /><br />
                    <div>
                        <button type="submit" >Submit</button>
                    </div>
                    <br /><br />
                    
                </form>
                <div>
                    <button onClick={() => handleResendClick()}>Resend Confirmation Email</button>
                    </div>
                    <br /><br />
                    <br />
                    <span className="close-link" onClick={() => handleRegisterClick()}>Close</span>
            </div>
            <br />
            <div className={`login-error ${errorMsg ? "hide" : "show"}`}>
                <p>{errorMsg}</p>
            </div>
        </div>
    )
}
