import React from "react";
import { SERVER_ADDRESS, useFetch } from "../strapi.js";
import { Link } from "react-router-dom";


export default function Articles({sliceIndex}) {
  const { loading, error, data } = useFetch(
    "/api/projects?populate=*",
    localStorage.getItem("token")
  );
  

  if (loading) {
    return (
      <div className="articles">
        <p>loading...</p>
      </div>
    );
  }
  if (error) {
    return (
      <div className="articles">
        <p>{JSON.stringify(error)}</p>
      </div>
    );
  }
  if (data) {
      return (
          <div className="articles">
        {data.slice((sliceIndex ? sliceIndex : 0), (sliceIndex ? (sliceIndex+3) : 3)).map((a) => {
          console.log(a);
          return (
            <article
              key={a.attributes.Title.toString()}
              style={{
                backgroundImage: `url(${SERVER_ADDRESS + (a.attributes.images.data ? a.attributes.images.data[0].attributes.url : '/uploads/No_Project_Img_cc993a93a7.jpg?updated_at=2023-02-20T17:58:16.958Z')})`,
              }}
            >
              <div className="hero-text">
                <h3>{a.attributes.Title.toString()}</h3>
                <Link to={"/ideas/" + a["id"]}>
                  <button>SEE PROJECT</button>
                </Link>
              </div>
            </article>
          );
        })}
      </div>
    );
  }
}
