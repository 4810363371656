import React, { useState } from 'react';
import { kaReducer, Table } from 'ka-table';
import { DataType, EditingMode, SortingMode } from 'ka-table/enums';
import "ka-table/style.css";

// const dataArray = Array(10)
//   .fill(undefined)
//   .map((_, index) => ({
//     column1: `column:1 row:${index}`,
//     column2: `column:2 row:${index}`,
//     column3: `column:3 row:${index}`,
//     column4: `column:4 row:${index}`,
//     id: index
//   }));

// {id: "1", name: "Nev", businessType: "best", pics: "asd",}

const dataArray = [
  {id: 0, name: "", email: "antonbg@mail.bg","info":"my cool idea","images":[]},
  {id: 2, name: "Ant", businessType: "best", pics: ["d","s","a"],},
]

const tablePropsInit = {
  columns: [
    { key: 'id', title: 'ID', dataType: DataType.Number },
    { key: 'name', title: 'Name', dataType: DataType.String },
    { key: 'businessType', title: 'Business Type', dataType: DataType.String },
    { key: 'pics', title: 'Images', dataType: DataType.String }
  ],
  data: dataArray,
  editingMode: EditingMode.None,
  rowKeyField: 'id',
  sortingMode: SortingMode.Single
};

const KaTable = () => {
  const [tableProps, changeTableProps] = useState(tablePropsInit);
  const dispatch = action => {
    changeTableProps(prevState => kaReducer(prevState, action));
  };

  return <Table {...tableProps} dispatch={dispatch} />;
};

export default KaTable;