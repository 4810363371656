import React from "react";
import { useState, useEffect } from "react";
import axios, { toFormData } from "axios";

export const SERVER_ADDRESS = "https://server.platform.anjela.info";

const client = axios.create({
  baseURL: SERVER_ADDRESS
});

export const useFetch = (url, token) => {
    console.log("fetching from... " + url)
    
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    let config = {
        headers: {}
    }

    if (token) {
        config = {
            headers: { Authorization: `Bearer ${token}` }
        }
    }

    const bodyParameters = {
    };

    useEffect(() => {
       const fetchData = async () => {
            setLoading(true)

            try {
                const res = await client.get(url, bodyParameters, config)
                    .catch(function (error) {
                        if (error.response) {
                            setError(error.response)

                            // The request was made and the server responded with a status code
                            // that falls out of the range of 2xx
                            console.log(error.response.data);
                            console.log(error.response.status);
                            console.log(error.response.headers);
                        } else if (error.request) {
                            setError(error.request)

                            // The request was made but no response was received
                            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                            // http.ClientRequest in node.js
                            console.log(error.request);
                        } else {
                            // Something happened in setting up the request that triggered an Error
                            console.log('Error', error.message);
                            setError(error.message)
                        }
                        console.log(error.config);
                    });
                    setData(res.data.data)
                    setLoading(false)
            } catch (error) {
                setError(error)
                setLoading(false)
            }
        }

        fetchData()
    }, [url, token])

    return { loading, error, data }
}

export default useFetch;




export function postRequest(url, data, token){
    console.log("posting to... " + url)
    
  
    let config = {
        headers: {}
    }
    
    if (token) {
        config = {
            headers: {
              "Authorization": `Bearer ${token}`
          }
        }
    }
    let errorObj = null;
                
            try {
                return client.post(url, data, config).then(x=> {
                  console.log(x)
                            console.log(x)
                          return x.data
                }) .catch(function (err) {
                        console.log(JSON.stringify(err))
                        if (err.response) {
                            
                            // The request was made and the server responded with a status code
                            // that falls out of the range of 2xx
                            console.log("response error: " + err.response)
                            errorObj = {error: err.message}
                        } else if (err.request) {
                            
                            // The request was made but no response was received
                            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                            // http.ClientRequest in node.js
                            console.log("request error: " + err.request);
                            errorObj = {error: err.message}
                        } else {
                            // Something happened in setting up the request that triggered an Error
                            console.log('Error: ' + err.message);
                            errorObj = {error: JSON.stringify(err)}
                        }
                        return Promise.resolve(errorObj);
                    });
          
            } catch (error) {
                errorObj = {error: error}
            }
            console.log(" return error promise ")
            return Promise.resolve(errorObj);
        }
  
  
  
export function putRequest(url, data, token){
    console.log("puting to... " + url)
    
  
    let config = {
        headers: {}
    }
    
    if (token) {
        config = {
            headers: {
              "Authorization": `Bearer ${token}`
          }
        }
    }
    let errorObj = null;
                
            try {
                return client.put(url, data, config).then(x=> {
                  console.log(x)
                            console.log(x)
                          return x.data
                }) .catch(function (err) {
                        console.log(JSON.stringify(err))
                        if (err.response) {
                            
                            // The request was made and the server responded with a status code
                            // that falls out of the range of 2xx
                            console.log("response error: " + err.response)
                            errorObj = {error: err.message}
                        } else if (err.request) {
                            
                            // The request was made but no response was received
                            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                            // http.ClientRequest in node.js
                            console.log("request error: " + err.request);
                            errorObj = {error: err.message}
                        } else {
                            // Something happened in setting up the request that triggered an Error
                            console.log('Error: ' + err.message);
                            errorObj = {error: JSON.stringify(err)}
                        }
                        return Promise.resolve(errorObj);
                    });
          
            } catch (error) {
                errorObj = {error: error}
            }
            console.log(" return error promise ")
            return Promise.resolve(errorObj);
        }
  
  
  
  // var friendlyHttpStatus = {
//   '200': 'OK',
//   '201': 'Created',
//   '202': 'Accepted',
//   '203': 'Non-Authoritative Information',
//   '204': 'No Content',
//   '205': 'Reset Content',
//   '206': 'Partial Content',
//   '300': 'Multiple Choices',
//   '301': 'Moved Permanently',
//   '302': 'Found',
//   '303': 'See Other',
//   '304': 'Not Modified',
//   '305': 'Use Proxy',
//   '306': 'Unused',
//   '307': 'Temporary Redirect',
//   '400': 'Bad Request',
//   '401': 'Unauthorized',
//   '402': 'Payment Required',
//   '403': 'Forbidden',
//   '404': 'Not Found',
//   '405': 'Method Not Allowed',
//   '406': 'Not Acceptable',
//   '407': 'Proxy Authentication Required',
//   '408': 'Request Timeout',
//   '409': 'Conflict',
//   '410': 'Gone',
//   '411': 'Length Required',
//   '412': 'Precondition Required',
//   '413': 'Request Entry Too Large',
//   '414': 'Request-URI Too Long',
//   '415': 'Unsupported Media Type',
//   '416': 'Requested Range Not Satisfiable',
//   '417': 'Expectation Failed',
//   '418': 'I\'m a teapot',
//   '429': 'Too Many Requests',
//   '500': 'Internal Server Error',
//   '501': 'Not Implemented',
//   '502': 'Bad Gateway',
//   '503': 'Service Unavailable',
//   '504': 'Gateway Timeout',
//   '505': 'HTTP Version Not Supported',
// };
